import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Paper, { PaperProps } from "@material-ui/core/Paper"
import Typography, { TypographyProps } from "@material-ui/core/Typography"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled, { CSSProperties } from "styled-components"
import { getLinkProps } from "../../helpers"
import {
  UitgelichteActiviteitFragment,
  UitgelichteErvaringFragment,
} from "../../__generated__/graphql-gatsby"

interface IProps {
  featured:
    | Maybe<UitgelichteActiviteitFragment>
    | Maybe<UitgelichteErvaringFragment>
  flip?: Maybe<boolean>
  onlyImg?: Maybe<boolean>
}

type bg = React.FC<PaperProps>
const BG: bg = props => <Paper elevation={5} {...props} />

const Wrapper = styled(Container)`
  display: grid;
  grid-template: 1fr / 1fr;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    align-items: stretch;
  }
`
const Column = styled.div`
  grid-column: 1 / 1;
  grid-row: 1 / 1;

  &:first-child {
    margin-right: auto;
    max-width: 720px;
    z-index: 1;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
      margin-top: ${({ theme }) => theme.spacing(6)}px;
      margin-bottom: ${({ theme }) => theme.spacing(12)}px;
      margin-left: auto;
    }
  }
  &:last-child {
    margin-left: auto;
    width: 100%;
    max-width: 720px;
    z-index: 2;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
      display: flex;
      flex-direction: column;
      margin-right: auto;
      height: 100%;
    }
  }
  &.img {
    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
      z-index: 1;
      max-width: 720px;
      margin-top: ${({ theme }) => theme.spacing(6)}px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: ${({ theme }) => theme.spacing(12)}px;
    }
  }
  &.text {
    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
      z-index: 2;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 720px;
      height: 100%;
    }
  }
`

const Background = styled(BG)`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing(6)}px ${theme.spacing(8)}px`};
`
const HeaderBg = styled(Background)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  background-color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: ${({ theme }) => theme.spacing(6)}px;
  margin-top: 33%;

  max-width: 578px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    max-width: 720px;
    margin-top: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(3)}px`};
    text-align: center;
  }
`

const DescBg = styled(Background)`
  display: flex;
  flex-direction: column;

  padding: ${({ theme }) => `${theme.spacing(3)}px ${theme.spacing(4)}px`};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin-top: auto;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: ${({ theme }) =>
      `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`};
  }
`

const Excerpt = styled(props => (
  <Typography {...props} variant="body2" component="div" />
))<TypographyProps<"div">>`
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    font-size: 0.775rem;
  }
`

const ExcerptButton = styled(Button)`
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    font-size: 0.775rem;
  }
`

const Img = styled(GatsbyImage)`
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  box-shadow: ${({ theme }) => theme.shadows[3]};
`
interface TextProps {
  title: string
  text?: Maybe<string>
  excerpt: string
  uri: string
  flip: boolean
  onlyImg: boolean
}
const Text: React.FC<TextProps> = ({
  title,
  text,
  excerpt,
  uri,
  flip,
  onlyImg,
}) => {
  const headerStyle = {
    [flip && !onlyImg ? "marginRight" : "marginLeft"]: "auto",
  }
  const buttonStyle = {
    [flip ? "marginLeft" : "marginRight"]: "auto",
  }

  return (
    <Column className="text" style={{ zIndex: 2, position: "relative" }}>
      <HeaderBg style={headerStyle as CSSProperties}>
        <Typography variant="h2">{title}</Typography>
        {text && (
          <Typography component="span" variant="caption">
            {text}
          </Typography>
        )}
      </HeaderBg>
      <DescBg>
        <Excerpt dangerouslySetInnerHTML={{ __html: excerpt }} />
        <ExcerptButton
          variant="outlined"
          color="secondary"
          style={buttonStyle}
          {...getLinkProps(uri)}
        >
          Lees meer...
        </ExcerptButton>
      </DescBg>
    </Column>
  )
}

interface ImgProps {
  img?: any
  altText: string
}

const Image: React.FC<ImgProps> = ({ img, altText }) => (
  <Column className="img" style={{ zIndex: 1, position: "relative" }}>
    {img && <Img image={img} alt={altText} />}
  </Column>
)

// Main component
const Featured: React.FC<IProps> = ({ featured, flip, onlyImg }) => {
  if (!featured) return null

  const imgNode = featured.featuredImage?.node
  const img = imgNode?.localFile?.childImageSharp?.gatsbyImageData
  const altText = String(imgNode?.altText)
  const excerpt = featured.excerpt
    ? featured.excerpt
    : "Meer weten? Klik hier onder op de knop."

  const textProps: TextProps = {
    title: String(featured.title),
    excerpt,
    uri: String(featured.uri),
    flip: Boolean(flip),
    onlyImg: Boolean(onlyImg),
    text:
      featured.__typename === "WpActiviteit"
        ? featured.activiteit_fields?.dates?.text
        : undefined,
  }

  return (
    <Wrapper>
      {flip ? (
        <>
          <Text {...textProps} />
          <Image img={img} altText={altText} />
        </>
      ) : (
        <>
          <Image img={img} altText={altText} />
          <Text {...textProps} />
        </>
      )}
    </Wrapper>
  )
}

export default Featured
